html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(48, 11, 11);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p a {
  color: rgb(48, 11, 11);
  text-decoration: underline;
  text-decoration-color: rgb(123, 179, 44);
}

p a:hover {
  color: rgb(123, 179, 44);
}

h3 {
  margin-bottom: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 1.5em;
  text-transform: uppercase;
}

#container-logo {
  padding-top: 10px;
  padding-bottom: 20px;
}

#container-nav,
#container-about,
#container-adaptive {
  background-color: rgb(235, 231, 231);
}

#container-nav a {
  color: rgb(48, 11, 11);
  text-transform: uppercase;
}

#container-footer a {
  color: rgb(255, 255, 255);
}

#container-nav a:hover,
#container-footer a:hover {
  color: rgb(123, 179, 44);
}

#container-header {
  background-color: #4c4a5a;
  background-image: url(/header.png);
  background-repeat: no-repeat;
  background-position: center;
  min-height: 500px;
  text-align: center;
}

#container-header h2 {
  color: rgb(255, 255, 255);
  font-weight: 900;
  font-size: 3em;
  text-transform: uppercase;
}

#container-environment {
  padding-top: 50px;
  padding-bottom: 50px;
}

#container-environment p {
  text-align: center;
}

#container-facility {
  color: rgb(255, 255, 255);
  background-color: rgb(123, 179, 44);
}

#container-about,
#container-facility {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

#container-about img,
#container-facility img {
  height: 75px;
  margin-bottom: 10px;
}

#container-facility .btn {
  background-color: rgb(48, 11, 11);
}

#container-security .btn,
.card-footer .btn {
  background-color: rgb(123, 179, 44);
}

#container-security {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: rgb(48, 11, 11);
  color: rgb(255, 255, 255);
  text-align: center;
}

#container-footer {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgb(48, 11, 11);
  color: rgb(255, 255, 255);
  text-align: center;
}

#container-footer hr {
  border-top: 1px solid rgb(255, 255, 255);
}

#container-lockers {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

#container-lair,
#container-bike {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

.card-header {
  background-color: rgb(48, 11, 11);
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 1.5em;
  text-transform: uppercase;
}

.card-footer {
  background-color: rgb(48, 11, 11);
}

.btn {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  border: none;
}

#container-adaptive {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

blockquote {
  border: 1px solid rgb(48, 11, 11);
  padding: 50px 0 0;
  /*quotes: "\201C""\201D""\2018""\2019";*/
}

blockquote p {
  padding: 0 50px;
}

blockquote:before {
  color: rgb(123, 179, 44);
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  display: block;
  clear: both;
  float: left;
  margin-left: 10px;
}

blockquote:after {
  color: rgb(123, 179, 44);
  content: close-quote;
  font-size: 4em;
  line-height: 0.1em;
  display: block;
  clear: both;
  float: right;
  margin-right: 10px;
}